import React from 'react'

// Images 
import whyImg from '../../assets/images/why-we/ww3.jpg'
import whyShape from '../../assets/images/shape/shape2.png'
import whyShape2 from '../../assets/images/shape/shape5.png'
import Achievements from '../Common/Achievements'

const AboutWhy = () => {
    return (
        <section className="why-only-we pt-100 bg-f8f8f8">
            <div className="container pb-100">
                <div className="row align-items-center">
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="why-only-we-text-area pr-20">
                            <div className="default-section-title">
                                <span>Why Only We</span>   
                                <h3>Reason For Choosing Our Strike Consultancy</h3>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, do eiusmod tempo incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrice. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                            <p>Iaculis erat pellentesque adipiscing commodo. Placerat vestibulum lectus mauris ultrices eros in cursus. Ornare aenean euismod elementum nisi quis eleifend quam adipiscing. Morbi tincidunt ornare massa eget egestas purus fermentum viverra accumsan commodo.</p>
                            <div className="why-we-list">
                                <ul>
                                    <li><i className="fas fa-check"></i> Trusted by leaders</li>
                                    <li><i className="fas fa-check"></i> Good Track Record</li>
                                    <li><i className="fas fa-check"></i> Diverse Portfolio</li>
                                    <li><i className="fas fa-check"></i> Exponential Growth</li>
                                    <li><i className="fas fa-check"></i> Pioneers in Consultancy</li>
                                    <li><i className="fas fa-check"></i> Brand Equity</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="why-only-img-area why-only-img-area-2">
                            <img 
                                className="ww-main-img" 
                                src={whyImg} 
                                alt="Why"
                            />
                            <div className="shape" >
                                <div className="shape1" data-speed="0.2" data-revert="true">
                                    <img 
                                        src={whyShape} 
                                        alt="Why"
                                    />
                                </div>
                                <div className="shape2" data-speed="0.1" data-revert="true">
                                    <img 
                                        src={whyShape2} 
                                        alt="Why"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Achievements />
        </section>
    )
}

export default AboutWhy
