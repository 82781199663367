import React from 'react'
import Layout from '../components/_App/Layout'
import Seo from '../components/_App/Seo'
import NavbarTwo from '../components/_App/NavbarTwo'
import PageBanner from '../components/Common/PageBanner'
import AboutTwo from '../components/Common/AboutTwo'
import AboutWhy from '../components/About/AboutWhy'
import Team from '../components/Common/Team'
import TestimonialsTwo from '../components/Common/TestimonialsTwo'
import WhyChooseTwo from '../components/Common/WhyChooseTwo'
import Partner from '../components/Common/Partner'
import Footer from '../components/_App/Footer'
import Copyright from '../components/_App/Copyright'

const AboutPage = () => {
    return (
        <Layout>
            <Seo title="About Page" />

            <NavbarTwo />

            <PageBanner 
                pageTitle="About Our Company" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="About Us" 
            />
            
            <AboutTwo />

            <AboutWhy />

            <Team />

            <div className="bg-f8f8f8">
                <TestimonialsTwo />
            </div>

            <WhyChooseTwo />

            <Partner />

            <Footer />

            <Copyright />
        </Layout>
    )
}

export default AboutPage
